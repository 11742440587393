.custom-text-color{
    color:  #1f2e59 !important;
}
.service-line-break {
    height: 10px;
    background-color: #1f2e59;
    width: 100%;
}
.row{
    justify-content: center;
}
