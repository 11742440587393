 $background-color-darkblue : #1f2e59;

 .background-color-blue {
     background-color: #85c0ce;
 }

 $background-color-white : #FFFFFF;
 $brand-name-font-color : #2fbd6b;

 .child-2-main-container {
     background-color: $background-color-darkblue;
     padding: 10px 20px;

     .banner {
         background-color: $background-color-white;

         h2 {
             color: $brand-name-font-color;
             font-weight: bold;
             text-align: center;
         }

         p {
             color: $background-color-darkblue;
             font-weight: bold;
             font-size: 20px;
             text-align: center;
         }

         .line-break {
             height: 10px;
             background-color: $background-color-darkblue;
         }

         .content {
             color: $background-color-darkblue;
             font-weight: normal;
             font-size: 18px;
             text-align: left;
         }

         ul {
             color: #1f2e59;
         }
     }
 }

 // side bar

 /* Custom styles for the navbar toggler icon */
 .navbar-toggler {
     border-color: transparent;
     /* Remove default border */
 }

 .navbar-toggler .navbar-toggler-icon {
     background-color: transparent;
     /* Ensure the background is transparent */
     border: none;
     /* Remove any border */
 }

 /* Creating three lines for the hamburger icon */
 .navbar-toggler .navbar-toggler-icon::before,
 .navbar-toggler .navbar-toggler-icon::after {
     content: '';
     display: block;
     width: 30px;
     /* Adjust width as needed */
     height: 2px;
     /* Thickness of the lines */
     background-color: black;
     /* Change this to your desired color */
     transition: all 0.3s ease;
     /* Smooth transition */
 }

 .navbar-toggler .navbar-toggler-icon::before {
     margin-bottom: 6px;
     /* Space between the first and second lines */
 }

 .navbar-toggler .navbar-toggler-icon::after {
     margin-top: 6px;
     /* Space between the second and third lines */
 }

 .navbar-sticky {
     position: sticky;
     top: 0px;
     z-index: 9999;
     //background: #85c0ce;
     background: #FFFFFF
 }


 //card


//  body {
//      min-height: 100vh;
//      display: flex;
//      align-items: center;
//      justify-content: center;
//  }

 .card-container {
     transform-style: preserve-3d;

     .card-box {
         position: relative;
         width: 340px;
         height: 300px;
         margin: 20px;
         transform-style: preserve-3d;
         perspective: 1000px;
         cursor: pointer;

         .card-body {
             position: absolute;
             top: 0;
             left: 0;
             width: 100%;
             height: 100%;
             transform-style: preserve-3d;
             transition: 0.9s ease;

             .card-imgContainer {
                 position: absolute;
                 top: 0;
                 left: 0;
                 width: 100%;
                 height: 100%;
                 transform-style: preserve-3d;

                 img {
                     position: absolute;
                     top: 0;
                     left: 0;
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                 }
             }

             .content {
                 position: absolute;
                 top: 0;
                 left: 0;
                 width: 100%;
                 height: 100%;
                 background: #333;
                 backface-visibility: hidden;
                 transform-style: preserve-3d;
                 transform: rotateY(180deg);

                 div {
                     transform-style: preserve-3d;
                     padding: 20px;
                     background: linear-gradient(45deg, #FE0061, #FFEB3B);
                     transform: translateZ(100px);

                     h3 {
                         letter-spacing: 1px;
                     }
                 }
             }
         }

         &:hover {
             .card-body {
                 transform: rotateY(180deg);
             }
         }
     }
 }

 .about-us{
    border: 1px solid #1f2e59;
    border-width: 15px;
 }